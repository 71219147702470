.retail-partners {
  padding: 110px 0;

  .container-page {
    .title {
      text-align: center;
      font-size: 45px;
      line-height: 70px;
      font-weight: 500;
      font-family: Cormorant Garamond;
      letter-spacing: 0px;
      color: #000000;
      margin-bottom: 14px;
      text-transform: capitalize;
    }

    .subtitle {
      text-align: center;
      font: normal normal normal 16px/28px Inter;
      letter-spacing: 0px;
      color: #000000;
      opacity: 0.7;
      margin-bottom: 47px;
    }

    .container-images {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      
      @media (max-width: 768px) {
        display: none;
      }

      .logos {
        width: calc(100% / 5);
        margin-bottom: 42px;
        min-width: 170px;
        object-fit: contain;
        height: 75px;
      }
    }

    .container-images-mobile {
      display: none;
    }

    .tns-outer {
      button {
        display: none;
      }

      .tns-nav {
        display: flex;
        width: 100%;
        justify-content: center;

        button {
          display: block;
          width: 8px;
          height: 8px;
          background: #C3C3C3;
          margin-right: 12px;
          border-radius: 50%;

          &.tns-nav-active {
            background: #7990A9;
          }

          &:last-child {
            margin-right: 0;
          }
        }

        @media (min-width: 768px) {
          display: none;
        }
      }
    }

    .container-images-mobile {      
      display: flex;

      @media (min-width: 768px) {
        display: none;
      }

      .item {
        display: grid;
        height: max-content;
        grid-template-columns: repeat(2, 50%);
        justify-content: center;
        align-content: center;

        .logos {
          width: 50%;
          margin-bottom: 42px;
          min-width: 170px;
          object-fit: cover;
          height: auto;
          margin-left: auto;
          margin-right: auto;

          @media(max-width:390px) {
            min-width: 145px;
          }

          @media(max-width:389px) {
            min-width: 120px;
          }
        }
      }
    }
  }
}